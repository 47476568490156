<template>
  <div class="wrap" :style="verConfig.loginBG && { backgroundImage: `url(${verConfig.loginBG})` }
    ">
    <!-- <div class="nav">
      <Icon name="down" class="back" size="26" @click="handleClose" />
      <div class="title">{{ $t("Login.Daftar.title") }}</div>
    </div> -->
    <div class="logo">
      <!-- <VImg
        height="100"
        :src="require(`@tt/assets/ico/${ver}.png`)"
        fit="scale-down"
      /> -->
      <img :src="require('@/assets/logo/login.png')" class="logo_img" alt="">
      <!-- <VImg height="60" :src="require('@/assets/logo/login.png')" /> -->
    </div>
    <div class="deline">
      <div class="left"></div>
      <div class="text">{{ $t("partner") }}</div>
      <div class="right"></div>
    </div>
    <div class="partners">
      <img :src="require(`@tt/assets/login/patner/all.png`)" alt="" class="partner_img">
    </div>
    <div class="form_wrap_bar">
      <LoginForm :formList="formList" cancelText="Login.Forgot.goBack" confirmText="Login.Forgot.confirm"
        readText="hint.registeRead" :needRead="true" @cancel="handleClose" @submit="onSubmit">
      </LoginForm>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VImg, Toast } from "vant";
import LoginForm from "./components/form.vue";
import { SEND_SMS_CODE, LOGIN } from "@/api";
import { getNewPhone } from "@/utils/tools";
import { getCookieValue, baseEncode } from "@/utils/common";
export default {
  components: {
    Icon,
    VImg,
    LoginForm,
  },
  data() {
    return {
      formList: [
        {
          key: "userphone",
          flag: true,
          icon: "icon-yonghuming",
          // label: "Login.Daftar.userName",
          label: "Login.nameInput",
          rules: [{ required: true, message: this.$t("hint.usernameEnter") }],
        },
        {
          label: "Login.Daftar.password",
          type: "password",
          key: "userpass",
          icon: "icon-zhanghumima",
          flag: true,
          rules: [{ required: true, message: this.$t("hint.passwordEnter") }],
          isPassword: true,
        },
        {
          label: "Login.Daftar.againPassword",
          type: "password",
          key: "againPassword",
          icon: "icon-zhanghumima",
          flag: false,
          rules: [
            { required: true, message: this.$t("hint.passwordAgainEnter") },
          ],
          isPassword: true,
        },
        {
          label: "Login.Daftar.referrer",
          type: "text",
          icon: "icon-yaoqing2",
          key: "fromid",
          flag: true,
          rules: [{ required: true, message: this.$t("hint.formidEnter") }],
        },
      ],
    };
  },
  methods: {
    onSubmit(values) {
      Toast.loading({ duration: 0 });
      const { againPassword, ...useValues } = values;
      if (againPassword === useValues.userpass) {
        if (!this.formList.find((item) => item.key === "code")) {
          useValues.code = "112233";
        }
        LOGIN({ ...useValues, channel: getCookieValue('channel') || baseEncode() })
          .then((r) => {
            if (r.data.ret === 1) {
              this.$cookie.set("userInfo", JSON.stringify(r.data.data));
              this.$cookie.set("token", r.data.data.token);
              if (this.$cookie.get("fromid")) {
                this.$cookie.remove("fromid");
              }
              this.$store.dispatch("getSystemConfig");
              this.$router.replace("goods");
            } else {
              Toast.clear();
              Toast(r.data.msg);
            }
          })
          .catch((e) => {
            Toast.clear();
            console.log(e);
          });
        return;
      }
      Toast(this.$t("Hint.pwdInconformity"));
    },
    handleClose() {
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: var(--bg);
  min-height: 100vh;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .nav {
    // height: calc(50rem / 16);
    color: var(--textColor);
    display: flex;
    align-items: center;
    margin: calc(26rem / 16) calc(17rem / 16) 0;

    .back {
      transform: rotate(90deg);
    }

    .title {
      font-size: calc(24rem / 16);
      margin-left: calc(14rem / 16);
    }
  }

  .deline {
    padding: 0 calc(17rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(30rem / 16);
    color: var(--light);

    .left {
      flex: 1;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .text {
      margin: 0 calc(10rem / 16);
    }

    .right {
      .left();
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
  }

  .partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: calc(15rem / 16) calc(17rem / 16) calc(15rem / 16);

    >.partner_img {
      // height: 60px;
      width: 100%;
    }
  }

  .form_wrap_bar {
    margin: calc(17rem / 16);
    background-color: rgba(255, 255, 255, .3);
    padding: calc(1rem / 16) 0 calc(30rem / 16);
    border-radius: 8px;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-top: calc(26rem / 16);
    &_img{
      height: calc(60rem / 16);
      display: block;
    }
  }
}
</style>